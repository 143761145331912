<template>
  <div class="custom-table-wrapper mr-6">
    <v-expansion-panels multiple>
      <!--       minmax        -->
      <hb-expansion-panel
        v-for="(rule, index) in currentState"
        :key="rule.rule_name"
        :class="isEdit[index] && !rule.is_new ? 'cyan-border' : ''"
        :expand-icon-off="rule.denied_values && rule.denied_values.length == 0"
      >
        <template v-slot:title>{{ rule.rule_name }}</template>
        <template v-slot:actions>
          <div
            v-if="rule.denied_values && rule.denied_values.length == 0"
            style="margin-right: 1%; color: #00848e"
          >
            Click to add
          </div>
        </template>
        <template v-slot:content>
          <div class="text-input" v-if="isEdit[index]">
            <v-col>
              <label class="group-name"> Group Name</label>
              <HbTextField
                @click.native.stop
                large
                v-model="ruleNames[index]"
                v-validate="'required|min:2'"
                data-vv-scope="default"
                data-vv-as="default"
                data-vv-name="primary_value"
                :error="errors.has('default.primary_value')"
                :placeholder="ruleNames[index]"
                @change="validate('default')"
                style="font-size: x-large"
            /></v-col>
          </div>
          <div class="box-input" v-if="isEdit[index]">
            <v-row>
              <div style="width: 300px">
                <HbTextField
                  class="codebox"
                  box
                  v-model="codeInputs[index]"
                  @click.native.stop
                  extra-large
                  v-validate="'min:2'"
                  data-vv-scope="codebox"
                  data-vv-as="codebox"
                  data-vv-name="code_value"
                  :error="errors.has('codebox.code_value')"
                  :placeholder="'Enter Prohibited Code'"
                  @change="validate('codebox')"
                />
              </div>
              <hb-btn
                prepend-icon="mdi-plus"
                @click="addCode(index)"
                color="secondary"
                class="mb-1"
              >
                Add
              </hb-btn>
            </v-row>
          </div>
          <div class="chips">
            <hb-chip
              v-for="(val, key) in rule.denied_values"
              @click="deleteCode(index, val)"
              :editable="isEdit[index]"
            >
              {{ val }}
            </hb-chip>
          </div>
        </template>
        <!-- footer  -->
        <template v-slot:footer>
          <v-divider class="divider-gray" />
          <hb-bottom-action-bar
            :top-border="false"
            :footer-cancel-option="isEdit[index]"
            @close="toggleEdit(index)"
          >
            <template v-slot:left-actions>
              <div class="footer-slot">
                <HbIcon
                  mdi-code="mdi-account-clock-outline"
                  class="mx-2"
                  small
                />
                <div
                  style="padding-top: 2px"
                  v-if="currentState[index].last_updated_by"
                >
                  {{
                    `${currentState[index].last_updated_by} (${formatDate(
                      currentState[index].modified_at
                    )})`
                  }}
                </div>
              </div>
            </template>
            <template v-slot:right-actions>
              <hb-btn
                v-if="isEdit[index]"
                @click="deleteRule(index)"
                :color="'destructive'"
              >
                {{ "Delete" }}
              </hb-btn>
              <hb-btn
                @click="isEdit[index] ? save(index) : toggleEdit(index)"
                :color="isEdit[index] ? 'primary' : 'secondary'"
              >
                {{ isEdit[index] ? "Save" : "Update" }}
              </hb-btn>
            </template>
          </hb-bottom-action-bar>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import api from "../../../assets/api";
import lodash from "lodash";
import moment from "moment";
const postChanges = async (oldState, newState, owner, delete_all = false) => {
  const last_edited_by = `${owner.first} ${owner.last}`;
  const last_edited_id = `${owner.id}`;

  const old = oldState?.denied_values ?? [];
  const neww = newState?.denied_values ?? [];
  const rule_name = oldState.is_new ? newState.rule_name : oldState.rule_name;
  const add_values = neww.filter((i) => !old.includes(i));
  const delete_values = old.filter((i) => !neww.includes(i));
  try {
    const data = {
      rule_name,
      add_values,
      delete_values,
      delete_all,
      last_edited_by,
      last_edited_id,
    };
    if (rule_name != newState.rule_name && !oldState.is_new) {
      data.new_name = newState.rule_name;
    }
    api.post(this, api.ACCESS_CONTROL + "add_delete_pin_rules", data);
  } catch (err) {}
};

const JUST_NOW = "Just now";
export default {
  components: {},
  name: "CustomPinRulesAccordion",
  props: {
    customRules: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      test: "5",
      isEdit: [],
      oldState: [],
      currentState: [],
      ruleNames: [],
      codeInputs: [],
    };
  },
  methods: {
    deleteCode(index, val) {
      const idx = this.currentState[index].denied_values.indexOf(val);
      this.currentState[index].denied_values.splice(idx, 1);
    },
    initializeState() {
      Object.entries(this.customRules).forEach(([rule_name, details]) => {
        this.ruleNames.push(rule_name.trim());
        this.currentState.push({
          rule_name: rule_name.trim(),
          denied_values: details.denied_values,
          last_edited_id: details.last_updated_by.last_edited_id,
          last_updated_by: details.last_updated_by.last_updated_by,
          modified_at: details.modified_at,
        });
      });
      this.oldState = lodash.cloneDeep(this.currentState);
      this.isEdit = Array(this.currentState.length || 0).fill(false);
      this.codeInputs = Array(this.currentState.length || 0).fill("");
    },
    syncParentAdd(newRules) {
      Object.entries(newRules).forEach(([rule_name, details], key) => {
        if (!(key in this.currentState)) {
          this.codeInputs.push("");
          this.isEdit.push(true);
          this.ruleNames.push(rule_name.trim());
          const v = {
            rule_name: rule_name.trim(),
            denied_values: details.denied_values,
            last_edited_id: details.last_updated_by.last_edited_id,
            last_updated_by: details.last_updated_by.last_updated_by,
            modified_at: details.modified_at,
            is_new: details.is_new,
          };
          this.currentState.push(v);
          this.oldState.push(lodash.cloneDeep(v));
        }
      });
    },
    formatDate(date) {
      if (date == JUST_NOW) return JUST_NOW;
      return moment(date, "YYYY-MM-DD HH:mm:ss")
        .local()
        .format("MMM DD, YYYY @h.mmA");
    },
    toggleEdit(index) {
      if (this.isEdit[index]) {
        this.ruleNames[index] = this.oldState[index].rule_name;
        this.$set(
          this.currentState,
          index,
          lodash.cloneDeep(this.oldState[index])
        );
      }
      this.$set(this.isEdit, index, !this.isEdit[index]);
    },
    addCode(index) {
      const val = this.codeInputs[index].toString().trim();
      if (
        this.currentState[index].denied_values.includes(val) ||
        val.length < 2
      ) {
        return;
      }
      this.currentState[index].denied_values.push(val);
      this.codeInputs[index] = "";
    },
    save(index) {
      const owner = this.$store.getters["authenticationStore/getUserData"];
      const lastEditedBy = `${owner.first} ${owner.last}`;
      const rule_name = this.ruleNames[index].trim();
      this.$set(this.currentState, index, {
        ...this.currentState[index],
        rule_name: rule_name,
        last_updated_by: lastEditedBy,
        modified_at: JUST_NOW,
        is_new: false,
      });
      postChanges(this.oldState[index], this.currentState[index], owner);
      this.oldState[index] = lodash.cloneDeep(this.currentState[index]);
      this.$set(this.isEdit, index, !this.isEdit[index]);
    },
    deleteRule(index) {
      const owner = this.$store.getters["authenticationStore/getUserData"];
      postChanges(this.oldState[index], this.currentState[index], owner, true);
      const rule_name = this.oldState[index].rule_name;
      this.currentState.splice(index, 1);
      this.oldState.splice(index, 1);
      this.isEdit.splice(index, 1);
      this.codeInputs.splice(index, 1);
      this.ruleNames.splice(index, 1);
      this.$delete(this.customRules, rule_name);
    },
    async validate(param) {
      this.$validator.validateAll(param).then((valid) => {
        if (!valid) {
          this.list = true;
          this.type = "error";
          this.snackbar = true;
        }
      });
    },
  },
  watch: {
    customRules: {
      handler(newRules) {
        this.syncParentAdd(newRules);
      },
    },
  },
  created() {
    this.initializeState();
  },
};
</script>

<style scoped>
.custom-table-wrapper {
  width: 90%;
}
.chips {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 60px;
}

.divider-gray {
  border-color: rgba(0, 0, 0, 0.2);
  opacity: 0.7;
}

.cyan-border {
  border: 1px solid rgb(71, 192, 191);
}
.footer-slot {
  display: flex;
  align-items: center;
  margin-left: 1%;
  width: 400px;
}
.text-input {
  margin-left: 1%;
}
.box-input {
  margin-left: 2rem;
}
.codebox {
  margin-right: 15px;
}
.group-name {
  color: rgba(0, 0, 0, 0.8);
  font-size: small;
}
</style>
