<template>
  <div class="custom-table-wrapper mr-6">
    <v-expansion-panels multiple>
      <!--       minmax        -->
      <hb-expansion-panel
        v-model="isPanelOpen.minmax"
        :class="isEdit.minmax ? 'cyan-border' : ''"
        @click="handlePanelClick(0)"
      >
        <template v-slot:title>Min & Max Code Length</template>
        <template v-slot:actions>
          <div style="margin-right: 1%">
            Min:
            <strong>{{ commonRules.minmax.primary_value || "4" }}</strong> -
            Max:
            <strong>{{ commonRules.minmax.secondary_value || "20" }}</strong>
          </div>
          <hb-switch
            class="mx-2"
            @click.native.stop
            v-model="commonRules.minmax.is_active"
            @change="handleSwitchChange(0)"
          />
        </template>

        <template v-slot:content>
          <p class="descriptions text-gray">
            Set the range for your pin code length. These settings are corporate defaults but can be overriden at the property level. 
          </p>
          <v-divider class="divider-gray" />

          <div class="input-box">
            <label style="padding-top: 3px">Minimum Length:</label>
            <HbTextField
              :disabled="!isEdit.minmax"
              condensed
              box
              x-small
              v-model="commonRules.minmax.primary_value"
              v-validate="'required|integer|min_value:2|max_value:20'"
              name="primary_value"
              data-vv-as="Repeated Value"
              data-vv-scope="min"
              :error="errors.has('min.primary_value')"
              :placeholder="commonRules.minmax.primary_value || '4'"
              @change="validate('min')"
            />
            <label style="padding-top: 3px">Maximum Length:</label>
            <HbTextField
              :disabled="!isEdit.minmax"
              condensed
              box
              x-small
              v-model="commonRules.minmax.secondary_value"
              v-validate="'required|integer|min_value:2|max_value:20'"
              name="primary_value"
              data-vv-as="Repeated Value"
              data-vv-scope="max"
              :error="errors.has('max.primary_value')"
              :placeholder="commonRules.minmax.secondary_value || '20'"
              @change="validate('max')"
            />
          </div>
        </template>
        <template v-slot:footer>
          <v-divider class="divider-gray" />
          <hb-bottom-action-bar
            :top-border="false"
            :footer-cancel-option="isEdit.minmax"
            @close="toggleEdit(0)"
          >
            <template v-slot:left-actions>
              <div class="footer-slot">
                <HbIcon
                  mdi-code="mdi-account-clock-outline"
                  class="mx-2"
                  small
                />
                <div
                  style="padding-top: 2px"
                  v-if="commonRules?.minmax?.last_updated_by?.last_updated_by"
                >
                  {{
                    `${
                      commonRules?.minmax.last_updated_by.last_updated_by
                    } (${formatDate(commonRules.minmax.modified_at)})`
                  }}
                </div>
              </div>
            </template>
            <template v-slot:right-actions>
              <hb-btn
                @click="isEdit.minmax ? save(0) : toggleEdit(0)"
                :color="isEdit.minmax ? 'primary' : 'secondary'"
              >
                {{ isEdit.minmax ? "Save" : "Edit" }}
              </hb-btn>
            </template>
          </hb-bottom-action-bar>
        </template>
      </hb-expansion-panel>
      <!--          sequence        -->
      <hb-expansion-panel
        :class="isEdit.sequence ? 'cyan-border' : ''"
        @click="handlePanelClick(1)"
      >
        <template v-slot:title> Sequential Digits </template>
        <template v-slot:actions>
          <div style="margin-right: 1%">
            # of digits:
            <strong>{{ commonRules.sequence.primary_value || "3" }}</strong>
          </div>
          <hb-switch
            class="mx-2"
            @click.native.stop
            v-model="commonRules.sequence.is_active"
            @change="handleSwitchChange(1)"
          />
        </template>
        <template v-slot:content>
          <p class="descriptions text-gray">
            Set a limit for the number of sequential digits allowed.
          </p>
          <v-divider class="divider-gray" />

          <div class="input-box">
            <label style="padding-top: 3px">Limit: </label>
            <HbTextField
              :disabled="!isEdit.sequence"
              condensed
              box
              x-small
              v-model="commonRules.sequence.primary_value"
              v-validate="'required|integer|min_value:2|max_value:20'"
              name="primary_value"
              data-vv-as="Repeated Value"
              data-vv-scope="sequence"
              :error="errors.has('sequence.primary_value')"
              :placeholder="commonRules.sequence.primary_value || '3'"
              @change="validate('sequence')"
            />
          </div>
          <v-divider class="divider-gray" />
          <div class="example-box">
            <span
              >ex.
              <span style="color: red">{{
                example(1, commonRules.sequence.primary_value, "+")
              }}</span
              >712, 8<span style="color: red">{{
                example(1, commonRules.sequence.primary_value, "-")
              }}</span
              >9</span
            >
          </div>
          <v-divider />
        </template>
        <template v-slot:footer>
          <hb-bottom-action-bar
            :top-border="false"
            :footer-cancel-option="isEdit.sequence"
            @close="toggleEdit(1)"
          >
            <template v-slot:left-actions>
              <div class="footer-slot">
                <HbIcon
                  mdi-code="mdi-account-clock-outline"
                  class="mx-2"
                  small
                />
                <div
                  style="padding-top: 2px"
                  v-if="commonRules?.sequence?.last_updated_by?.last_updated_by"
                >
                  {{
                    `${
                      commonRules?.sequence.last_updated_by?.last_updated_by
                    } (${formatDate(commonRules.sequence?.modified_at)})`
                  }}
                </div>
              </div>
            </template>
            <template v-slot:right-actions>
              <hb-btn
                @click="isEdit.sequence ? save(1) : toggleEdit(1)"
                :color="isEdit.sequence ? 'primary' : 'secondary'"
              >
                {{ isEdit.sequence ? "Save" : "Edit" }}
              </hb-btn>
            </template>
          </hb-bottom-action-bar>
        </template>
      </hb-expansion-panel>
      <!--        repeated        -->
      <hb-expansion-panel
        :class="isEdit.repeated ? 'cyan-border' : ''"
        @click="handlePanelClick(2)"
      >
        <template v-slot:title> Repeated Digits </template>
        <template v-slot:actions>
          <div style="margin-right: 1%">
            # of digits:
            <strong>{{ commonRules.repeated.primary_value || "2" }}</strong>
          </div>
          <hb-switch
            class="mx-2"
            @click.native.stop
            v-model="commonRules.repeated.is_active"
            @change="handleSwitchChange(2)"
          />
        </template>
        <template v-slot:content>
          <p class="descriptions text-gray">
            Set a limit for the number of repeated digits allowed.
          </p>
          <v-divider class="divider-gray" />

          <div class="input-box">
            <label style="padding-top: 3px">Limit:</label>
            <HbTextField
              :disabled="!isEdit.repeated"
              condensed
              box
              x-small
              v-model="commonRules.repeated.primary_value"
              v-validate="'required|integer|min_value:2|max_value:20'"
              name="primary_value"
              data-vv-as="Repeated Value"
              data-vv-scope="default"
              :error="errors.has('default.primary_value')"
              :placeholder="commonRules.repeated.primary_value || '2'"
              @change="validate('default')"
            />
          </div>
          <v-divider class="divider-gray" />
          <div class="example-box">
            <span
              >ex.
              <span style="color: red">{{
                example(2, commonRules.repeated.primary_value)
              }}</span
              >712, 8<span style="color: red">{{
                example(2, commonRules.repeated.primary_value)
              }}</span
              >9</span
            >
          </div>
          <v-divider class="divider-gray" />
        </template>
        <template v-slot:footer>
          <hb-bottom-action-bar
            :top-border="false"
            :footer-cancel-option="isEdit.repeated"
            @close="toggleEdit(2)"
          >
            <template v-slot:left-actions>
              <div class="footer-slot">
                <HbIcon
                  mdi-code="mdi-account-clock-outline"
                  class="mx-2"
                  small
                />
                <div
                  style="padding-top: 2px"
                  v-if="commonRules?.repeated?.last_updated_by?.last_updated_by"
                >
                  {{
                    `${
                      commonRules?.repeated.last_updated_by?.last_updated_by
                    } (${formatDate(commonRules.repeated?.modified_at)})`
                  }}
                </div>
              </div>
            </template>
            <template v-slot:right-actions>
              <hb-btn
                @click="isEdit.repeated ? save(2) : toggleEdit(2)"
                :color="isEdit.repeated ? 'primary' : 'secondary'"
              >
                {{ isEdit.repeated ? "Save" : "Edit" }}
              </hb-btn>
            </template>
          </hb-bottom-action-bar>
        </template>
      </hb-expansion-panel>
      <!--        mirror        -->
      <hb-expansion-panel
        :class="isEdit.mirror ? 'cyan-border' : ''"
        @click="handlePanelClick(3)"
      >
        <template v-slot:title>
          <span>Palindrome </span>
          <span class="mirr"> (mirrored codes) </span>
        </template>
        <template v-slot:actions>
          <hb-switch
            class="mx-2"
            @click.native.stop
            v-model="commonRules.mirror.is_active"
            @change="handleSwitchChange(3)"
          />
        </template>
        <template v-slot:content>
          <p class="descriptions text-gray">
            A palindrome is a sequence that reads the same backward as forward.
          </p>
          <v-divider class="divider-gray" />
          <div class="example-box">
            <span
              >ex. <span style="color: red">3443</span>,
              <span style="color: red">12521</span></span
            >
          </div>
          <v-divider class="divider-gray" />
        </template>
        <template v-slot:footer>
          <hb-bottom-action-bar
            :top-border="false"
            :footer-cancel-option="false"
            @close="toggleEdit(2)"
          >
            <template v-slot:left-actions>
              <div class="footer-slot">
                <HbIcon
                  mdi-code="mdi-account-clock-outline"
                  class="mx-2"
                  small
                />
                <div
                  style="padding-top: 2px"
                  v-if="commonRules?.repeated?.last_updated_by?.last_updated_by"
                >
                  {{
                    `${
                      commonRules?.repeated.last_updated_by?.last_updated_by
                    } (${formatDate(commonRules.repeated?.modified_at)})`
                  }}
                </div>
              </div>
            </template>
          </hb-bottom-action-bar>
        </template>
      </hb-expansion-panel>
      <!--        leading       -->
      <hb-expansion-panel
        :class="isEdit.leading ? 'cyan-border' : ''"
        @click="handlePanelClick(4)"
      >
        <template v-slot:title> Leading Digit </template>
        <template v-slot:actions>
          <div style="margin-right: 1%">
            Prohibited digits:
            <strong>{{ commonRules.leading.primary_value || "0" }}</strong>
          </div>
          <hb-switch
            class="mx-2"
            @click.native.stop
            v-model="commonRules.leading.is_active"
            @change="handleSwitchChange(4)"
          />
        </template>
        <template v-slot:content>
          <p class="descriptions text-gray">
            Set the prohibited leading digits
          </p>
          <v-divider class="divider-gray" />

          <div class="input-box">
            <label style="padding-top: 3px">Prohibited Digits:</label>
            <HbCheckbox
              v-for="digit in digits"
              :key="digit"
              v-model="selectedLeadingDigits[digit]"
              :readonly="!isEdit.leading"
              :label="digit.toString()"
            />
          </div>
          <v-divider class="divider-gray" />
          <div
            class="example-box"
            v-if="Object.values(selectedLeadingDigits).some(Boolean)"
          >
            <span
              >ex.
              <span style="color: red">{{
                example(4, commonRules.leading.primary_value, "+")
              }}</span
              >712,
              <span style="color: red">{{
                example(4, commonRules.leading.primary_value, "-")
              }}</span
              >936</span
            >
          </div>
          <v-divider class="divider-gray" />
        </template>
        <template v-slot:footer>
          <hb-bottom-action-bar
            :top-border="false"
            :footer-cancel-option="isEdit.leading"
            @close="toggleEdit(4)"
          >
            <template v-slot:left-actions>
              <div class="footer-slot">
                <HbIcon
                  mdi-code="mdi-account-clock-outline"
                  class="mx-2"
                  small
                />
                <div
                  style="padding-top: 2px"
                  v-if="commonRules?.leading?.last_updated_by?.last_updated_by"
                >
                  {{
                    `${
                      commonRules?.leading.last_updated_by?.last_updated_by
                    } (${formatDate(commonRules.leading?.modified_at)})`
                  }}
                </div>
              </div>
            </template>
            <template v-slot:right-actions>
              <hb-btn
                @click="isEdit.leading ? save(4) : toggleEdit(4)"
                :color="isEdit.leading ? 'primary' : 'secondary'"
              >
                {{ isEdit.leading ? "Save" : "Edit" }}
              </hb-btn>
            </template>
          </hb-bottom-action-bar>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import moment from "moment";
import api from "../../../assets/api";
function getAnumber(length = 1) {
  if (length <= 0) {
    length = 1;
  }
  let min = Math.pow(10, length - 1);
  let max = Math.pow(10, length) - 1;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const convertLeadingArrayToString = (selectedDigits) => {
  return Object.entries(selectedDigits)
    .filter(([digit, isSelected]) => isSelected)
    .map(([digit]) => digit)
    .join(",");
};

const RULE_NAMES = {
  minmax: "minmax",
  sequence: "sequence",
  repeated: "repeated",
  mirror: "mirror",
  leading: "leading",
};

const postChanges = async (pv, sv, name, is_active, owner) => {
  const lastEditedBy = `${owner.first} ${owner.last}`;
  const lastEditId = `${owner.id}`;
  try {
    const data = {
      rule_name: name,
      primary_value: pv,
      secondary_value: sv,
      is_active: !!is_active,
      last_edited_by: lastEditedBy,
      last_edited_id: lastEditId,
    };
    api.post(this, api.ACCESS_CONTROL + "save_common_pin_rule", data);
  } catch (err) {}
};
const JUST_NOW = "Just now";
export default {
  name: "CorporatePinRulesAccordion",
  props: {
    commonRules: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPanelOpen: {
        minmax: false,
        sequence: false,
        repeated: false,
        mirror: false,
        leading: false,
      },
      isEdit: {
        minmax: false,
        sequence: false,
        repeated: false,
        mirror: false,
        leading: false,
      },
      original: {
        minmax1: null,
        minmax2: null,
        sequence: null,
        repeated: null,
      },
      digits: Array.from({ length: 10 }, (_, i) => i),
      selectedLeadingDigits: {},
    };
  },
  methods: {
    handlePanelClick(index) {
      if (index == 0) {
        this.isPanelOpen.minmax = !this.isPanelOpen.minmax;
      } else if (index == 1) {
        this.isPanelOpen.sequence = !this.isPanelOpen.sequence;
      } else if (index == 2) {
        this.isPanelOpen.repeated = !this.isPanelOpen.repeated;
      } else if (index == 3) {
        this.isPanelOpen.mirror = !this.isPanelOpen.mirror;
      } else if (index == 4) {
        this.isPanelOpen.leading = !this.isPanelOpen.leading;
      }
    },
    async validate(param) {
      this.$validator.validateAll(param).then((valid) => {
        if (!valid) {
          this.list = true;
          this.type = "error";
          this.snackbar = true;
        }
      });
    },
    toggleEdit(index) {
      if (index == 0) {
        if (this.isEdit.minmax) {
          this.commonRules.minmax.primary_value = this.original.minmax1;
          this.commonRules.minmax.secondary_value = this.original.minmax2;
        }
        this.isEdit.minmax = !this.isEdit.minmax;
      } else if (index == 1) {
        if (this.isEdit.sequence)
          this.commonRules.sequence.primary_value = this.original.sequence;
        this.isEdit.sequence = !this.isEdit.sequence;
      } else if (index == 2) {
        if (this.isEdit.repeated)
          this.commonRules.repeated.primary_value = this.original.repeated;
        this.isEdit.repeated = !this.isEdit.repeated;
      } else if (index == 3) {
        this.isEdit.mirror = !this.isEdit.mirror;
      } else if (index == 4) {
        if (this.isEdit.leading) {
          this.selectedLeadingDigits = { ...this.original.leading };
        }
        this.isEdit.leading = !this.isEdit.leading;
      }
    },
    save(index) {
      const owner = this.$store.getters["authenticationStore/getUserData"];
      const lastEditedBy = `${owner.first} ${owner.last}`;
      const lastEditId = `${owner.id}`;
      if (
        index == 0 &&
        parseInt(this.commonRules.minmax.primary_value ?? 4) <=
          parseInt(this.commonRules.minmax.secondary_value ?? 20)
      ) {
        this.commonRules.minmax.modified_at = JUST_NOW;
        this.commonRules.minmax.last_updated_by = {
          last_updated_by: lastEditedBy,
          last_updated_id: lastEditId,
        };
        this.original.minmax1 = this.commonRules.minmax.primaxdary_value;
        this.isEdit.minmax = !this.isEdit.minmax;
        postChanges(
          this.commonRules.minmax.primary_value ?? "4",
          this.commonRules.minmax.secondary_value ?? "20",
          RULE_NAMES.minmax,
          this.commonRules.minmax.is_active,
          owner
        );
      } else if (
        index == 1 &&
        parseInt(this.commonRules.sequence.primary_value ?? 3) > 1 &&
        parseInt(this.commonRules.sequence.primary_value ?? 3) < 21
      ) {
        this.commonRules.sequence.modified_at = JUST_NOW;
        this.commonRules.sequence.last_updated_by = {
          last_updated_by: lastEditedBy,
          last_updated_id: lastEditId,
        };
        this.original.sequence = this.commonRules.sequence.primary_value;
        this.isEdit.sequence = !this.isEdit.sequence;
        postChanges(
          this.commonRules.sequence.primary_value ?? "3",
          null,
          RULE_NAMES.sequence,
          this.commonRules.sequence.is_active,
          owner
        );
      } else if (
        index == 2 &&
        parseInt(this.commonRules.repeated.primary_value ?? 2) > 1
      ) {
        this.commonRules.repeated.last_updated_by = {
          last_updated_by: lastEditedBy,
          last_updated_id: lastEditId,
        };
        this.original.repeated = this.commonRules.repeated.primary_value;
        this.isEdit.repeated = !this.isEdit.repeated;
        this.commonRules.repeated.modified_at = JUST_NOW;
        postChanges(
          this.commonRules.repeated.primary_value ?? "2",
          null,
          RULE_NAMES.repeated,
          this.commonRules.repeated.is_active,
          owner
        );
      } else if (index == 3) {
        this.commonRules.mirror.last_updated_by = {
          last_updated_by: lastEditedBy,
          last_updated_id: lastEditId,
        };
        this.commonRules.mirror.modified_at = JUST_NOW;
        this.isEdit.mirror = false;
        postChanges(
          null,
          null,
          RULE_NAMES.mirror,
          this.commonRules.mirror.is_active,
          owner
        );
      } else if (
        index == 4 &&
        Object.values(this.selectedLeadingDigits).some(Boolean)
      ) {
        this.commonRules.leading.last_updated_by = {
          last_updated_by: lastEditedBy,
          last_updated_id: lastEditId,
        };
        this.commonRules.leading.primary_value = convertLeadingArrayToString(
          this.selectedLeadingDigits
        );
        this.commonRules.leading.modified_at = JUST_NOW;
        this.original.leading = { ...this.selectedLeadingDigits };
        this.isEdit.leading = false;
        postChanges(
          this.commonRules.leading.primary_value ?? "0",
          null,
          RULE_NAMES.leading,
          this.commonRules.leading.is_active,
          owner
        );
      }
    },
    formatDate(date) {
      if (date == "Just now") return "Just now";
      return moment(date, "YYYY-MM-DD HH:mm:ss")
        .local()
        .format("MMM DD, YYYY @h.mmA");
    },
    handleSwitchChange(index) {
      const owner = this.$store.getters["authenticationStore/getUserData"];
      if (index == 0) {
        postChanges(
          this.commonRules.minmax.primary_value ?? "4",
          this.commonRules.minmax.secondary_value ?? "20",
          RULE_NAMES.minmax,
          this.commonRules.minmax.is_active,
          owner
        );
      } else if (index == 1) {
        postChanges(
          this.commonRules.sequence.primary_value ?? "3",
          null,
          RULE_NAMES.sequence,
          this.commonRules.sequence.is_active,
          owner
        );
      } else if (index == 2) {
        postChanges(
          this.commonRules.repeated.primary_value ?? "2",
          null,
          RULE_NAMES.repeated,
          this.commonRules.repeated.is_active,
          owner
        );
      } else if (index == 3) {
        postChanges(
          null,
          null,
          RULE_NAMES.mirror,
          this.commonRules.mirror.is_active,
          owner
        );
      } else if (index == 4) {
        postChanges(
          this.commonRules.leading.primary_value ?? "0",
          null,
          RULE_NAMES.leading,
          this.commonRules.leading.is_active,
          owner
        );
      }
    },
    example(index, v, op) {
      if (index == 1) {
        const value = parseInt(v || 3);
        if (op == "+") {
          return Array.from(
            { length: Math.min(value, 9) },
            (_, i) => i + 1
          ).join("");
        }
        return Array.from({ length: Math.min(value, 9) }, (_, i) =>
          Math.abs(9 - i)
        ).join("");
      }
      if (index == 2) {
        const value = parseInt(v || 2);
        const val = getAnumber().toString();
        return val.repeat(Math.min(value, 20));
      }
      if (index == 4) {
        let left = op == "+" ? 0 : 9;
        let iterate = op == "+" ? 1 : -1;

        for (left; op == "+" ? left < 10 : left >= 0; left += iterate) {
          if (this.selectedLeadingDigits[left]) {
            return left;
          }
        }
      }
    },
  },
  created() {
    this.original.minmax1 = this.commonRules.minmax.primary_value;
    this.original.minmax2 = this.commonRules.minmax.secondary_value;
    this.original.sequence = this.commonRules.sequence.primary_value;
    this.original.repeated = this.commonRules.repeated.primary_value;
    this.selectedLeadingDigits = Object.fromEntries(
      Array.from({ length: 10 }, (_, i) => [i, false])
    );
    if (!this.commonRules.leading.primary_value)
      this.commonRules.leading.primary_value = "0";
    this.commonRules.leading.primary_value.split(",").forEach((digit) => {
      if (digit in this.selectedLeadingDigits) {
        this.selectedLeadingDigits[digit] = true;
      }
    });
    this.original.leading = { ...this.selectedLeadingDigits };
  },
  destroyed() {
    this.$validator.errors.clear();
  },
};
</script>

<style scoped>
.custom-table-wrapper {
  width: 90%;
}
.descriptions {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 30px;
}
.text-gray {
  color: rgba(0, 0, 0, 0.6);
}

.divider-gray {
  border-color: rgba(0, 0, 0, 0.2);
  opacity: 0.7;
}
.input-box {
  display: flex;
  margin-left: 2%;
  margin-top: 1.5%;
  margin-bottom: 1%;
  gap: 10px;
}
.example-box {
  display: flex;
  margin-left: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  gap: 10px;
}
.cyan-border {
  border: 1px solid rgb(71, 192, 191);
}
.footer-slot {
  display: flex;
  align-items: center;
  margin-left: 1%;
  width: 400px;
}
.mirr {
  font-weight: 100;
  opacity: 0.8;
  margin-left: 1.5%;
  width: 300px;
}
</style>
