
<template>
    <div class="section-content ma-0 pa-0 automation">
        <v-row class="px-0 pt-0 mt-n1">
            <v-col
                v-if="selected_group.id" 
                sm="12"
            >
                <hb-breadcrumb @click="$emit('removeSelected')">Back to All Workflows</hb-breadcrumb>
                <GroupProperties :group="selected_group" :triggersv2="triggersv2" @manageStage="manageStage" @deleteStage="deleteStage" @editProperties="editProperties" ></GroupProperties>
            </v-col>
        </v-row>

        
         <!-- <AddEditWorkflow
            v-if="show_edit_workflow"
            v-model="show_edit_workflow"
            @close="closeWindow"
            size="lg"
            slot="body"
            :activeGroupID="groupID"
        ></AddEditWorkflow> -->
        <v-navigation-drawer
            :value="showAside"
            width="1200"
            fixed
            stateless
            class="z-250">
              <ManageStage v-if="showAside" @close="closeDrawer" @refresh="refresh" :selected_stage="selected_stage" :isOps="isOps" :group_id="selected_group.id" :properties="selected_group.Properties" :sectionIdentifier="'manage-stage'"/>
        </v-navigation-drawer>
         <v-overlay
            :value="showAside"
        ></v-overlay>



        <hb-modal v-model="show_delete_stage" show-help-link size="medium" title="Delete Stage" confirmation @close="closeDeleteStage">
            <template v-slot:content>
                <div class="py-4 px-6">
                    Are you sure you want to delete the following stage? <br /><br />
                    <strong>{{selected_stage.name}}</strong>
                    <br /><br />
                    This action cannot be undone.
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="destructive" @click="confirmDeleteStage">Delete</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
import Dropdown from "../../assets/Dropdown.vue";
import { mapGetters, mapActions } from "vuex";
import api from "../../../assets/api.js";
import Header from "../../header/Index.vue";
import GroupProperties from "./GroupProperties.vue"
import Status from '../../includes/Messages.vue'
import ManageStage from './ManageStage.vue'
import AddEditWorkflow from "./AddEditWorkflow.vue";


export default {
    name: "Automation",
    data() {
        return {
            panel: [0, 1],
            show_delete_stage: false,
            show_edit_workflow: false, 
            selected_group: {},
            selected_stage: {},
            phone_types: ["Cell", "Home", "Work", "Other"],
            triggerHeaders: [{ text: "Triggers", value: "name" }],
            showAside: false,
            showNavigation: true,
            navigationHeading: "Automation",
            open_sections: ["gate"],
            selectedAction: null,
            appliedActions: [],
            actionTypesList: [],
            triggers: [],
            triggersv2:[],
            // trigger: {
            //     id: "",
            //     name: "",
            //     description: "",
            //     trigger_reference: "",
            //     start: "",
            //     repeat: 0,
            //     max_repeat: 0,
            //     deny_access: "",
            //     overlock: "",
            //     lease_standing_id: "",
            //     trigger_group_id: "",
            //     apply_to_all: false,
            //     Actions: []
            // },
            trigger_list: [
                { id: "start_of_lease", label: "After start of lease" },
                { id: "past_due", label: "After account is past due" },
                { id: "end_of_lease", label: "Before end of lease" }
            ],
            Attachment: {
                document_id: null  
            },
            Email: {
                subject: null,
                message: "[FIRSTNAME] [LASTNAME],"
            },
            SMS: {
                message: "[FIRSTNAME] [LASTNAME],"
            },
            Task: {
                event_type_id: null,
                contact_id: null,
                todo: 1,
                details: null
            },
            changeStatus: {
                lease_standing_id: null
            },
            Fee: {
                product_id: null
            },
            admins: [],
            products: [],
            editingAction: false,
            event_types: [],
            lease_standings: [],
            editActionIndex: null,
            
            
        };
    },
    props: ['group',"isOps"],
    components: {
        Header,
        GroupProperties,
        Status,
        ManageStage,
        AddEditWorkflow
    },
    created() {
        this.selected_group = this.group; 
        this.getActionTypes();
        this.fetchAdmins();
        this.fetchEventTypes();
        this.fetchLeaseStandings();
        this.fetchProducts();
        this.getTriggersByGroup();
    },
    computed: {
        ...mapGetters({
            actionTypes: "triggersStore/actionTypes",
            getUserData: "authenticationStore/getUserData"
        }),
    },
    methods: {
        ...mapActions({
            getActionTypes: "triggersStore/getActionTypes"
        }),
        editProperties(g){
            
            this.$emit('editProperties', g)
        }, 
        deleteStage(stage){

            this.selected_stage = stage;
            this.show_delete_stage = true;
        }, 
        async confirmDeleteStage(){
            await api.delete(this, api.TRIGGERS + this.selected_stage.id);
            await this.getTriggersByGroup();
            this.closeDeleteStage();
        }, 
        closeDeleteStage(){
            this.selected_stage = {};
            this.show_delete_stage = false;
        }, 

        manageStage(stage){
            
            this.selected_stage = stage || {}; 
            this.showAside = true;
        }, 
        async getTriggersByGroup(){
            let p = await api.get(this, api.TRIGGERS + "?trigger_group_id=" + this.selected_group.id);
            this.triggers = JSON.parse(JSON.stringify(p.triggers));
            this.triggersv2 = JSON.parse(JSON.stringify(p.triggers));
            this.$store.commit('automationStore/setAllTriggers', p.triggers || []); 
        }, 
        refresh(){
            this.$emit('refresh')
            this.getTriggersByGroup();
        }, 
        closeDrawer(){
            
            this.selected_stage = {};
            this.showAside = false;
        },
        fetchProducts() {
            api.get(this, api.PRODUCTS + "?search=late&category_type=delinquency").then(
                r => {
                    this.products = r.products;
                }
            );
        },
        async fetchLeaseStandings() {
            api.get(this, api.SETTINGS + "lease-standings").then(r => {
                this.lease_standings = r.lease_standings;
            });
        },
        async fetchEventTypes(){
            api.get(this, api.EVENTS + 'event-types?source=automation').then(r => {
                this.event_types = r.event_types.sort((a, b) => a.name.localeCompare(b.name));
            });
        },
       
        isOpen(section) {
            return this.open_sections.indexOf(section) >= 0;
        },
        toggleSection(section) {
            if (this.open_sections.indexOf(section) >= 0) {
                this.open_sections.splice(
                    this.open_sections.indexOf(section),
                    1
                );
            } else {
                this.open_sections.push(section);
            }
        },
        async fetchAdmins() {
            api.get(this, api.ADMIN).then(r => {
                this.admins = r.admins
                    .filter(a => a.contact_id !== this.getUserData.id)
                    .map(a => {
                        return {
                            id: a.contact_id,
                            name: a.first + " " + a.last
                        };
                    }).sort((a, b) => a.name.localeCompare(b.name));
                this.admins.unshift({
                    id: -1,
                    name: "Everyone"
                });
                this.admins.unshift({
                    id: this.getUserData.id,
                    name: "Me"
                });
            });
        },
        
    },
    watch: {
        groupPropetiesId() {
            this.groupId = this.groupPropetiesId
        }
    }
};
</script>

<style lang="scss" scoped>
.lease-section .new-tenant-walkthrough-footer {
    padding: 3px 0 3px 30px;
    background: #ffffff;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
}

.new-tenant-walkthrough-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.new-tenant-walkthrough-content-section {
    align-items: stretch;
    display: flex;
    overflow: hidden;
    padding-bottom: 81px;
}
.new-tenant-walkthrough {
    background: #f9fafb;
    width: 100%;
}
.v-stepper__header {
    max-width: 525px;
    border: none;
    box-shadow: none;
    margin: 0 auto;
}
.new-tenant-stepper {
    border-bottom: 1px solid #e1e6ea;
    text-align: center;
    box-shadow: none;
    background: white;
}
.new-tenant-info-panel {
    flex: 0 0 420px;
    height: 100%;
    background-color: #f9fafb;
    overflow: scroll;
    border-left: 1px solid #e1e6ea;
}
.walkthrough-body {
    flex: 1;
}

.nav-drawer-header {
    flex: 0 1 auto;
    min-height: 72px;
}
.onboarding-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    flex: 1;
}

.facility-text {
    font-size: 14px;
}

.subdued {
    color: #a5adb4;
    line-height: 18px;
}

.primary-section-content {
    display: flex;
    height: calc(100% - 70px);
    flex: 1 1 0;
    .v-navigation-drawer--open {
        flex: 1 1 auto;
    }
}

.main-panel-body {
    height: 100%;
    display: flex;
}

.main-panel-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    flex: 1;
}

.main-panel-content {
    flex: 1;
    margin-bottom: 91px;
    overflow: auto;
}

.main-side-info-panel {
    flex: 0 0 350px;
    height: 100%;
    background-color: #f9fafb;
    border-left: 1px solid #e1e6ea;
}

.navigation-panel-container {
    background: #f9fafb;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.panel-content {
    height: 100%;
    overflow: hidden;
    padding-bottom: 63px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.nav-drawer-header {
    background: white;
    flex: 0 1;
}

.section-header {
    font-weight: 500;
}

.interaction-text {
    padding-bottom: 10px;
}
.interaction-text {
    padding: 16px 0 16px 24px;
    background-color: white;
}
.interaction-section {
    margin-bottom: 8px;
    border-radius: 4px;
    box-shadow: 0px 2px 0px rgba(11, 18, 29, 0.1),
        0px 2px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
}
.interaction-section-body {
    height: 0;
    overflow: hidden;
    background: white;
}
.interaction-section-body.open {
    height: auto;
    border-top: 1px solid #dce8ef;
}
.interaction-section h2 {
    font-size: 14px;
    line-height: 24px;
    color: #101318;
    background-color: #ffffff;
    box-sizing: border-box;
    font-weight: normal;
    margin: 0;
}
.back-to-workflows {
    color: #00848E;
    text-decoration: none;
}
.back-to-workflows:hover {
    text-decoration: underline;
    cursor: pointer;
}
.back-to-workflows:focus {
    border: none;
}
.no-actions {
    border: 1px dashed #e0e3e8;
    box-sizing: border-box;
    border-radius: 8px;
}
.applied-actions {
    background: #ffffff;
    border: 1px solid #e0e3e8;
    box-sizing: border-box;
    border-radius: 8px;
}
.night-light-color {
    color: #637381;
}
.merge-fields:active {
    background-color: unset;
}
.merge-fields::before {
    background-color: unset;
}
.merge-fields:focus {
    border: unset;
}
.merge-fields-list {
    max-height: 35vh;
    max-height: 35svh;
    overflow: hidden auto;
}
</style>