<template>
  <hb-expansion-panel
    key="freeCoverage"
    :left-cols="8"
    :right-cols="4"
  >
    <template v-slot:title>
      <span class="pt-1">Free Coverage Period</span>
      <span class="pl-6" @click.stop>
        <hb-checkbox
          v-if="isPropertyLevel"
          v-model="insuranceData.useCorporateDefaults"
          @change="handleUseCorporateDefaults"
          :readonly="!insuranceData.is_found"
          label="Use Corporate Default"
          @mousedown.prevent
        >
          <template v-slot:label>
            <span class="checkbox-label">Use Corporate Default</span>
          </template>
        </hb-checkbox>
      </span>
    </template>
    <template v-slot:actions>
      <span>
        <hb-switch
          right
          class="mx-2"
          v-model="insuranceData.is_enabled"
          @change="handleFreeCoverageToggle($event)"
          :readonly="disableForm"
          :disabled="disableForm"
          @click.native.stop
          @mousedown.prevent
        >
          <template v-slot:label class="toggleText">
            {{ insuranceData.is_enabled ? 'Enabled' : 'Disabled' }}
          </template>
        </hb-switch>
      </span>
    </template>

    <template v-slot:content>
      <hb-form
        v-if="coverageType === INSURANCE.TYPES.LIBRARY.KEY"
        label="Maximum Free Premium Amount"
        description="Enter maximum Free Coverage premium amount in dollars."
        required
        full
      >
        <template v-slot:tooltipBody> Maximum Free Coverage premium threshold in dollars </template>
        <HbTextField
          box
          condensed
          medium
          hide-details
          id="premium_threshold"
          name="premium_threshold"
          v-model="insuranceData.premiumThreshold"
          v-validate="'required|max:32|numeric'"
          data-vv-name="premiumThreshold"
          data-vv-as="Premium Threshold"
          :error="errors.has('premium_threshold')"
          @input="errors.clear('premium_threshold')"
          :disabled="disableForm"
        >
          <template v-slot:prepend-inner> $ </template>
        </HbTextField>
      </hb-form>
      <hb-form
        v-else
        label="Maximum Free Premium Amount"
        description="Enter maximum Free Coverage premium amount in dollars or based on coverage values."
        full
        required
      >
        <template v-slot:tooltipBody> Maximum Free Coverage premium threshold in dollars or based on coverage values </template>
        <hb-radio-group
          row
          v-model="insuranceData.premiumThresholdType"
          v-if="isTableRateInsuranceSaved"
          :disabled="disableForm"
        >
          <hb-radio value="premium">
            <template v-slot:label> Dollars </template>
            <template v-slot:tooltipBody> Maximum amount in dollars that will be discounted from insurance premium </template>
          </hb-radio>
          <hb-radio value="coverage">
            <template v-slot:label> Coverage </template>
            <template v-slot:tooltipBody> Maximum coverage of insurance, against which the premium will be discounted </template>
          </hb-radio>
        </hb-radio-group>
        <HbTextField
          v-if="insuranceData.premiumThresholdType === 'premium'"
          box
          condensed
          medium
          hide-details
          id="premium_threshold"
          name="premium_threshold"
          v-model="insuranceData.premiumThreshold"
          v-validate="'required|max:32|numeric'"
          data-vv-name="premiumThreshold"
          data-vv-as="Premium Threshold"
          :error="errors.has('premium_threshold')"
          @input="errors.clear('premium_threshold')"
          :disabled="disableForm"
        >
          <template v-slot:prepend-inner> $ </template>
        </HbTextField>
        <v-row
          class="pl-4"
          v-else
        >
          <HbTextField
            box
            condensed
            small
            hide-details
            id="premium_threshold"
            name="premium_threshold"
            v-model="insuranceData.premiumThreshold"
            v-validate="'required|max:32|numeric'"
            data-vv-name="premiumThreshold"
            data-vv-as="Premium Threshold"
            :error="errors.has('premium_threshold')"
            @input="errors.clear('premium_threshold')"
            :disabled="disableForm"
          >
            <template v-slot:prepend-inner> $ </template>
          </HbTextField>
          <div :class="['hb-inline-sentence-text', disableForm ? 'hb-text-lighter' : '']">,000</div>
        </v-row>
      </hb-form>
      <hb-form
        label="Maximum Cancellation Period"
        description="Define the maximum numbers of days to allow the tenant to cancel. The invoice for coverage for next period will be generated after the below days have passed after free coverage is added"
        full
        required
      >
        <template v-slot:tooltipBody> Numbers of days before generating the next invoice after the Free Coverage Period has been initiated </template>
        <HbTextField
          box
          condensed
          x-small
          hide-details
          id="invoice_days_threshold"
          name="invoice_days_threshold"
          v-model="insuranceData.invoiceDaysThreshold"
          v-validate="'required|min_value:1|max_value:28|numeric'"
          data-vv-name="invoice_days_threshold"
          data-vv-as="Invoice Days Threshold"
          @input="errors.clear('invoice_days_threshold')"
          :error="errors.has('invoice_days_threshold')"
          :disabled="disableForm"
        >
        </HbTextField>
      </hb-form>
      <space-selector
        :selectedSpaceTypes="insuranceData.selectedSpaceTypes"
        :useCorporateDefaults="insuranceData.useCorporateDefaults"
        :isPropertyLevel="isPropertyLevel"
        @spaceTypeUpdate="spaceTypesUpdated"
      >
      </space-selector>
      <hb-bottom-action-bar
        v-if="showSaveButton"
        @close="handleCancel"
      >
        <template v-slot:right-actions>
          <hb-btn @click="toggleWarningModal({shouldCheckCancellationPeriod: true})">Save</hb-btn>
        </template>
      </hb-bottom-action-bar>
      <hb-modal
        size="medium"
        v-model="confirmFreeInsuranceModifications"
        title="Update Free Coverage Period Settings"
        @close="toggleWarningModal"
        confirmation
      >
        <template v-slot:content>
          <div class="px-6 py-4">
            Are you sure you want to update the Maximum Cancellation Period?
            Updating the Maximum Cancellation Period may impact invoice generation for tenants currently enrolled in the free coverage period
          </div>
        </template>
        <template v-slot:actions>
          <hb-btn color="destructive" @click="saveFreeInsurance"> Update </hb-btn>
        </template>
      </hb-modal>
      <hb-modal
            size="medium"
            v-model="confirmUncheckCorporateDefault"
            title="Update Corporate Default Status"
            @close="resetCorporateDefaultFreeInsurance"
            confirmation
          >
            <template v-slot:content>
              <div class="px-6 py-4">
                Are you sure you want to update the Corporate Default Status?
                This will delete your property level data.
              </div>
            </template>
            <template v-slot:actions>
              <hb-btn
                color="destructive"
                @click="setDefaultFreeInsurance()"
                >Update</hb-btn
              >
            </template>
          </hb-modal>
    </template>
  </hb-expansion-panel>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from "../../../mixins/notificationMixin.js";
import SpaceSelector from "./SpaceSelector.vue";
import INSURANCE from "@/constants/insurance.js";
import { cloneDeep, isEqual, omit } from "lodash";

export default {
  name: "FreeInsurance",
  mixins: [notificationMixin],
  props: {
    activeTab: {
      type: String,
      default: "",
    },
    level: {
      type: String,
      default: "",
    },
    coverageType: {
      type: String,
      default: "",
    },
    isOPS: {
      type: Boolean,
      default: false,
    },
    insurance: {
      required: true
    },
    savedInsuranceTemplates: {
      required: true
    },
  },
  data() {
    return {
      unitType: {},
      confirmUncheckCorporate: false,
      confirmUncheckCorporateDefault: false,
      insuranceData: {},
      confirmFreeInsuranceModifications: false,
    };
  },
  async created() {
    this.INSURANCE = INSURANCE;
    this.insuranceData = cloneDeep(this.insurance);
  },
  components: {
    SpaceSelector,
  },
  computed: {
    ...mapGetters({
      defaultProperty: "propertiesStore/defaultProperty",
      properties: "propertiesStore/filtered",
      hasRolePermission: "authenticationStore/rolePermission",
      unitTypes: "filterStore/unit_type",
    }),
    showSaveButton() {
      const filteredInsurance = omit(this.insurance, ['useCorporateDefaults', 'is_enabled']);
      const filteredInsuranceData = omit(this.insuranceData, ['useCorporateDefaults', 'is_enabled']);
      
      const isSettingChanged = !isEqual(filteredInsuranceData, filteredInsurance);
      return this.level === "property"  
        ? !this.insuranceData.useCorporateDefaults && this.insuranceData.is_found && isSettingChanged
        : isSettingChanged;
    },
    isPropertyLevel() {
      return this.level === "property";
    },
    isTableRateInsuranceSaved() {
      return this.savedInsuranceTemplates[this.activeTab] && this.savedInsuranceTemplates[this.activeTab].InsuranceTemplate.type === INSURANCE.TableRate;
    },
    disableForm(){
      return this.isPropertyLevel && this.insuranceData.useCorporateDefaults;
    }
  },

  watch: {
    insurance: {
      handler(val){
        this.insuranceData = cloneDeep(val);
      },
     deep: true
    },
  },

  methods: {
    ...mapActions({
      setDefaultProperty: "propertiesStore/setDefaultProperty",
    }),
    async validateOpenForm() {
      let status = await this.$validator.validateAll();
      if(!this.insuranceData.selectedSpaceTypes || (this.insuranceData.selectedSpaceTypes && this.insuranceData.selectedSpaceTypes.length < 1)){
        status = false;
        this.showMessageNotification({ description: 'Space Types can not be empty' });
      }
      return status;
    },
    spaceTypesUpdated(types){
      if(!types.includes(this.activeTab)){
        const currentUnit = this.unitTypes.find(x=>x.id === this.activeTab).name;
        this.showMessageNotification({ description: `${currentUnit} cannot be removed` });
        types.unshift(this.activeTab);
      }
      this.insuranceData.selectedSpaceTypes = types;
    },
    toggleWarningModal({shouldCheckCancellationPeriod = false}) {
      if (shouldCheckCancellationPeriod && Number(this.insuranceData.invoiceDaysThreshold) === Number(this.insurance.invoiceDaysThreshold)){
          this.saveFreeInsurance();
          return;
      }
      this.confirmFreeInsuranceModifications = !this.confirmFreeInsuranceModifications;
    },
    async saveFreeInsurance() {
      let status = await this.validateOpenForm();
      if (!status) return;
      await this.$emit("saveFreeInsurance", this.insuranceData);
      this.confirmFreeInsuranceModifications = false;
    },
    handleCancel() {
      this.$emit("handleCancelInsurance");
    },
    handleFreeCoverageToggle(value) {
      this.insuranceData.is_enabled = value || false;
      this.$emit("handleFreeCoverageToggle", { ...this.insuranceData });
    },
    handleUseCorporateDefaults(value) {
      if (value) this.confirmUncheckCorporateDefault = true;
      this.$emit("handleUseCorporateDefaults", value);
    },
    setDefaultFreeInsurance() {
      this.$emit("setDefaultFreeInsurance");
      this.confirmUncheckCorporateDefault = false;
    },
    resetCorporateDefaultFreeInsurance() {
      this.insuranceData.useCorporateDefaults = false;
    },
  },
};
</script>

<style lang="scss">
  .toggleText {
    font-size: 10px;
    font-weight: 300;
  }
  .checkbox-label{
    padding-top: 2px;
  }
</style>
