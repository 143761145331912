<template>
  <div>
    <div v-if="loading" class="page-loader">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="!loading">
      <template>
        <v-row class="pin-header align-center">
          <v-col cols="7">
            <h4>Prohibited Standard Access Codes</h4>
            <p>
              Manage common access code patterns which you can invalidate for
              your properties gates. Enabling a rule blocks those patterns from
              use for new codes.
            </p>
          </v-col>
          <v-row cols="5" class="justify-end" style="margin-bottom: 1px">
            <div style="display: flex; flex-direction: column">
              <HbTextField
                box
                v-model="validationCode"
                placeholder="Enter code"
                class="mr-2"
                :class="
                  codeInvalid == -1
                    ? 'red-border'
                    : codeInvalid == 1
                    ? 'green-border'
                    : ''
                "
                style="margin-bottom: 4px"
              />
              <div>
                {{
                  codeInvalid == -1
                    ? "code is blocked!"
                    : codeInvalid == 1
                    ? "code is allowed."
                    : ""
                }}
              </div>
            </div>
            <hb-btn @click="testValidation()" color="secondary" class="mb-1">
              Test Validation
            </hb-btn>
          </v-row>
        </v-row>
      </template>
      <div><CorporatePinRulesAccordion :commonRules.sync="commonRules" /></div>
    </div>
    <br />
    <div v-if="!loading">
      <template>
        <v-row class="pin-header align-center">
          <v-col cols="7">
            <h4>Prohibited Custom Access Codes</h4>
            <p>
              Build custom groups/categories of prohibited pin codes that don't
              fit in the standard rules
            </p>
          </v-col>
          <v-row cols="5" class="justify-end" style="margin-bottom: 12px">
            <hb-btn
              prepend-icon="mdi-plus"
              @click="addCustomGroup()"
              color="secondary"
              class="mb-1"
            >
              Add New Group
            </hb-btn>
          </v-row>
        </v-row>
      </template>
      <div><CustomPinRulesAccordion :customRules.sync="customRules" /></div>
    </div>
  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import lodash from "lodash";
import moment from "moment";
import CorporatePinRulesAccordion from "./CorporatePinRulesAccordion.vue";
import CustomPinRulesAccordion from "./CustomPinRulesAccordion.vue";

const COMMON_RULES = {
  minmax: { name: "Min and Max length limits", id: "minmax" },
  repeated: { name: "Repeated Digits", id: "repeated" },
  mirror: { name: "Palindrome / Mirror", id: "mirror" },
  sequential: { name: "Sequential Digits", id: "sequence" },
  leading: { name: "Leading digits", id: "leading" },
};
const checkDeniedValues = (obj, code) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const deniedValues = obj[key].denied_values;
      if (deniedValues.includes(code)) {
        return true; // Code matches a denied value
      }
    }
  }
  return false; // No match found
};
const isSequential = (str) => {
  if (str.length <= 1) return false;
  let isAsc = true;
  let isDesc = true;
  for (let i = 1; i < str.length; i++) {
    const diff = str.charCodeAt(i) - str.charCodeAt(i - 1);
    if (diff !== 1) isAsc = false;
    if (diff !== -1) isDesc = false;
    if (!isAsc && !isDesc) return false;
  }
  return isAsc || isDesc;
};

const testSequentialSubString = (code, val) => {
  if (val < 2 || val > code.length) return false;
  for (let i = 0; i <= code.length - val; i++) {
    if (isSequential(code.slice(i, i + val))) {
      return true;
    }
  }
  return false;
};
const checkCommonValues = (obj, code) => {
  for (let key in obj) {
    if (obj[key].is_active) {
      switch (key) {
        case COMMON_RULES.minmax.id:
          const min = parseInt(obj.minmax.primary_value);
          const max = parseInt(obj.minmax.secondary_value);
          if (min <= code.length && code.length <= max) {
            return false;
          }
          return true;
        case COMMON_RULES.repeated.id:
          const val = parseInt(obj.repeated.primary_value);
          if (val < 2 || val > 20) {
            return true;
          }
          const regex = new RegExp(`(.)\\1{${val - 1}}`);
          return regex.test(code);
        case COMMON_RULES.mirror.id:
          return code === code.split("").reverse().join("");
        case COMMON_RULES.sequential.id:
          const length = parseInt(obj.sequence.primary_value);
          return testSequentialSubString(code, length);
        case COMMON_RULES.leading.id:
          const vals = obj.leading.primary_value.split(",");
          if (code.length > 0 && vals.includes(code[0])) return true;
          return false;
      }
    }
  }
  return false;
};

export default {
  components: { CorporatePinRulesAccordion, CustomPinRulesAccordion },
  name: "CorporatePinRules",
  data() {
    return {
      loading: true,
      commonHasChanges: false,
      oldCustomRules: {},
      commonRules: {},
      customRules: {},
      customModalOpen: false,
      customModalState: {},
      inputLines: [""],
      validationCode: "",
      codeInvalid: 0,
      timeoutId: null,
    };
  },
  created() {
    this.fetchRules();
  },
  beforeDestroy() {
    // Clear the timeout to prevent memory leaks
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    async fetchRules() {
      const existing = await api.get(
        this,
        api.ACCESS_CONTROL + "get_common_and_custom_pin_rules"
      );
      let customResults = {};
      let commonResults = {
        [COMMON_RULES.minmax.id]: { is_active: false },
        [COMMON_RULES.sequential.id]: { is_active: false },
        [COMMON_RULES.repeated.id]: { is_active: false },
        [COMMON_RULES.mirror.id]: { is_active: false },
        [COMMON_RULES.leading.id]: { is_active: false },
      };
      // this function fills in the data from the server
      existing.forEach((element) => {
        if (element?.source_table == "common") {
          element.rule_name &&
            (commonResults[element.rule_name] = {
              is_active: !!element.is_active,
              primary_value: element.primary_value ?? "",
              secondary_value: element.secondary_value ?? "",
              last_updated_by: element.last_updated_by ?? {
                last_updated_by: "",
              },
              modified_at: element.modified_at ?? "",
            });
        } else {
          if (element?.denied_value == "#last_modified#") {
            (customResults[element.rule_name.trim()] = customResults[
              element.rule_name.trim()
            ] || {
              denied_values: [],
              last_updated_by: {},
            }).last_updated_by = element.last_updated_by;
            customResults[element.rule_name.trim()].modified_at =
              element.modified_at ?? "";
          } else {
            (customResults[element.rule_name.trim()] = customResults[
              element.rule_name.trim()
            ] || {
              denied_values: [],
              last_updated_by: {},
            }).denied_values.push(element.denied_value);
          }
        }
      });
      this.commonRules = commonResults;
      this.customRules = customResults;
      this.oldCustomRules = lodash.cloneDeep(customResults);
      this.loading = false;
    },
    prefix(item) {
      if (item.name === COMMON_RULES.minmax) {
        return `min:  ${item.value1} - max:  ${item.value2}`;
      } else if (item.name === COMMON_RULES.sequential) {
        return `number of digits:  ${item.value1}`;
      } else if (item.name === COMMON_RULES.repeated) {
        return `number of digits: ${item.value1} `;
      } else if (item.name === COMMON_RULES.leading) {
        return `banned digits: ${item.value1} - amount leading: ${item.value2} `;
      } else return `---`;
    },
    addCustomGroup() {
      const date = new Date();
      this.$set(
        this.customRules,
        `New Group ${moment(date, "YYYY-MM-DD HH:mm:ss")
          .format("MMM DD, YYYY @h.mm.ssA")
          .trim()}`,
        {
          denied_values: [],
          last_updated_by: {
            last_edited_id: "",
            last_updated_by: "",
          },
          modified_at: "",
          is_new: true,
        }
      );
    },
    async testValidation() {
      if (!this.validationCode.length) return;
      const timer = () => {
        this.timeoutId = setTimeout(() => {
          this.codeInvalid = 0;
        }, 1500);
      };
      this.codeInvalid = 1;
      if (
        checkDeniedValues(this.customRules, this.validationCode) ||
        checkCommonValues(this.commonRules, this.validationCode)
      ) {
        this.codeInvalid = -1;
        timer();
      } else {
        this.codeInvalid = 1;
        timer();
      }
    },
  },
};
</script>

<style scoped>
.pin-header {
  width: 90%;
  margin-bottom: -20px;
}
.page-loader {
  display: flex;
  width: 85%;
  justify-content: center;
  margin-top: 2%;
}
.red-border {
  border: 2px solid red;
}
.green-border {
  border: 2px solid green;
}
</style>
